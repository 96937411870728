/**
 * Do not edit directly
 * Generated on Mon, 07 Oct 2024 21:21:57 GMT
 */

export const BpXs = "0px";
export const BpSm = "375px";
export const BpMd = "768px";
export const BpLg = "1024px";
export const BpXl = "1440px";
export const ColorCostcoBlue = "#005dab";
export const ColorCostcoRed = "#e51937";
export const ColorGray100 = "#fafafa";
export const ColorGray150 = "#f5f5f5";
export const ColorGray200 = "#eeeeee";
export const ColorGray300 = "#eaeaea";
export const ColorGray400 = "#d9d9d9";
export const ColorGray500 = "#cccccc";
export const ColorGray600 = "#909090";
export const ColorGray700 = "#707070";
export const ColorGray800 = "#5f5f5f";
export const ColorGray900 = "#333333";
export const ColorBlue100 = "#eff7fb";
export const ColorBlue500 = "#005dab"; // alias of color-costco-blue
export const ColorBlue600 = "#0060a9";
export const ColorBlue700 = "#2a6293";
export const ColorGreen100 = "#dff0d8";
export const ColorGreen200 = "#85c66a";
export const ColorGreen500 = "#008000";
export const ColorGreen700 = "#0b6e22";
export const ColorRed500 = "#d32029";
export const ColorRed100 = "#fdd6d6";
export const ColorYellow200 = "#ffcc00";
export const ColorYellow100 = "#fff8d2";
export const ColorOrange = "#ca4d00";
export const ColorWhite = "#ffffff";
export const ColorBlack = "#000000";
export const ColorTextDefault = "#333333"; // alias of color-gray-900
export const ColorTextMedium = "#5f5f5f"; // alias of color-gray-800
export const ColorTextLight = "#707070"; // alias of color-gray-700
export const ColorTextLink = "#005dab"; // alias of color-blue-500
export const ColorTextError = "#d32029"; // alias of color-red-500
export const ColorTextSuccess = "#008000"; // alias of color-green-500
export const ColorTextCaution = "#ca4d00"; // alias of color-orange
export const ColorTextWhite = "#ffffff"; // alias of color-white
export const ColorBorderError = "#d32029"; // alias of color-red-500
export const ColorBorderSuccess = "#008000"; // alias of color-green-500
export const FontSize1 = "0.75rem";
export const FontSize2 = "0.875rem";
export const FontSize3 = "1rem";
export const FontSize4 = "1.125rem";
export const FontSize5 = "1.375rem";
export const FontSize6 = "1.5rem";
export const FontSize7 = "1.75rem";
export const FontSize100 = "0.75rem";
export const FontSize200 = "0.875rem";
export const FontSize300 = "1rem";
export const FontSize400 = "1.125rem";
export const FontSize500 = "1.375rem";
export const FontSize600 = "1.5rem";
export const FontSize700 = "1.75rem";
export const FontLineHeightLg = "1.7";
export const FontLineHeightMd = "1.5";
export const FontLineHeightSm = "1.3";
export const FontWeightDefault = "400";
export const FontWeightBold = "700";
export const GridCols = 12;
export const GridGutterWidth = 24;
export const GridOuterWidth = 16;
export const GridContainerXs = 288;
export const GridContainerSm = 736;
export const GridContainerMd = 992;
export const GridContainerLg = 1408;
export const RadiusSm = "2px";
export const RadiusMd = "3px";
export const SizeBorderWidthThin = "1px";
export const SizeBorderWidthMedium = "2px";
export const SizeBorderWidthThick = "3px";
export const SizeIconSvgSm = "16px";
export const SizeIconSvgMd = "32px";
export const SizeIconSvgLg = "48px";
export const SizeIconSvgXl = "60px";
export const SpaceXxs = "4px";
export const SpaceXs = "8px";
export const SpaceSm = "12px";
export const SpaceMd = "16px";
export const SpaceLg = "24px";
export const SpaceXl = "32px";
export const SpaceXxl = "48px";
export const SpaceXxxl = "64px";
export const SpaceInsetSm = "12px 12px 12px 12px";
export const SpaceInsetMd = "16px 16px 16px 16px";
export const SpaceInsetLg = "24px 24px 24px 24px";
export const SpaceInsetXl = "32px 32px 32px 32px";
export const TypeFontT1DesktopSize = "1.75rem"; // alias of font.size.700
export const TypeFontT1DesktopLineHeight = "1.3"; // alias of font.line-height.sm
export const TypeFontT1MobileSize = "1.5rem"; // alias of font.size.600
export const TypeFontT1MobileLineHeight = "1.3"; // alias of font.line-height.sm
export const TypeFontT2DesktopSize = "1.375rem"; // alias of font.size.500
export const TypeFontT2DesktopLineHeight = "1.3"; // alias of font.line-height.sm
export const TypeFontT2MobileSize = "1.125rem"; // alias of font.size.400
export const TypeFontT2MobileLineHeight = "1.3"; // alias of font.line-height.sm
export const TypeFontT3DesktopSize = "1.125rem"; // alias of font.size.400.value
export const TypeFontT3DesktopLineHeight = "1.3"; // alias of font.line-height.sm
export const TypeFontT3MobileSize = "1rem"; // alias of font.line-height.md
export const TypeFontT3MobileLineHeight = "1.5"; // alias of font.line-height.md
export const TypeFontT4DesktopSize = "1.125rem"; // alias of font.size.400
export const TypeFontT4DesktopLineHeight = "{font.line-height.sm.value"; // alias of font.line-height.sm
export const TypeFontT4MobileSize = "1.125rem"; // alias of font.size.400
export const TypeFontT4MobileLineHeight = "1.3"; // alias of font.line-height.sm
export const TypeFontT5DesktopSize = "1rem"; // alias of font.size.300
export const TypeFontT5DesktopLineHeight = "1.5"; // alias of font.line-height.md
export const TypeFontT5MobileSize = "1rem"; // alias of font.size.300
export const TypeFontT5MobileLineHeight = "1.5"; // alias of font.line-height.md
export const TypeFontT6DesktopSize = "0.875rem"; // alias of font.size.200
export const TypeFontT6DesktopLineHeight = "1.5"; // alias of font.line-height.md
export const TypeFontT6MobileSize = "0.875rem"; // alias of font.size.200
export const TypeFontT6MobileLineHeight = "1.5"; // alias of font.line-height.md
export const TypeFontT7DesktopSize = "0.75rem"; // alias of font.size.100
export const TypeFontT7DesktopLineHeight = "1.5"; // alias of font.line-height.md
export const TypeFontT7MobileSize = "0.75rem"; // alias of font.size.100
export const TypeFontT7MobileLineHeight = "1.5"; // alias of font.line-height.md
export const TypeFontBodyCopyDesktopSize = "0.875rem"; // alias of font.size.200
export const TypeFontBodyCopyDesktopLineHeight = "1.7"; // alias of font.line-height.lg
export const TypeFontBodyCopyMobileSize = "0.875rem"; // alias of font.size.200
export const TypeFontBodyCopyMobileLineHeight = "1.7"; // alias of font.line-height.lg
export const AnnouncementBannerColorBlueBackground = "#005dab"; // alias of color.costco-blue
export const AnnouncementBannerColorBlueText = "#ffffff"; // alias of color.text.white
export const AnnouncementBannerColorDarkGrayBackground = "#333333"; // alias of color.gray-900
export const AnnouncementBannerColorDarkGrayText = "#ffffff"; // alias of color.text.white
export const AnnouncementBannerColorGreenBackground = "#008000"; // alias of color.green-500
export const AnnouncementBannerColorGreenText = "#ffffff"; // alias of color.text.white
export const AnnouncementBannerColorOrangeBackground = "#D83F03";
export const AnnouncementBannerColorOrangeText = "#ffffff"; // alias of color.text.white
export const AnnouncementBannerColorPurpleBackground = "#800080";
export const AnnouncementBannerColorPurpleText = "#ffffff"; // alias of color.text.white
export const AnnouncementBannerColorRedBackground = "#e51937"; // alias of color.costco-red
export const AnnouncementBannerColorRedText = "#ffffff"; // alias of color.text.white
export const AnnouncementBannerColorYellowBackground = "#ffcc00"; // alias of color.yellow-200
export const AnnouncementBannerColorYellowText = "#333333"; // alias of color.text.default
export const AnnouncementBannerSizeBorderWidth = "0px";
export const ButtonsColorPrimaryDefaultBackground = "#005dab"; // alias of color.costco-blue
export const ButtonsColorPrimaryFocusBackground = "#2a6293"; // alias of color.blue-700
export const ButtonsColorPrimaryHoverBackground = "#2a6293"; // alias of color.blue-700
export const ButtonsColorPrimaryText = "#ffffff"; // alias of color.text.white
export const ButtonsColorPrimaryAlternateDefaultBackground = "#008000"; // alias of color.green-500
export const ButtonsColorPrimaryAlternateFocusBackground = "#0b6e22"; // alias of color.green-700
export const ButtonsColorPrimaryAlternateHoverBackground = "#0b6e22"; // alias of color.green-700
export const ButtonsColorPrimaryAlternateText = "#ffffff"; // alias of color.text.white
export const ButtonsColorSecondaryDefaultBackground = "#ffffff"; // alias of color.gray-300
export const ButtonsColorSecondaryFocusBackground = "#eeeeee"; // alias of color.gray-400
export const ButtonsColorSecondaryHoverBackground = "#eeeeee"; // alias of color.gray-200
export const ButtonsColorSecondaryText = "#005dab"; // alias of color.costco-blue
export const ButtonsColorSecondaryBorder = "#005dab"; // alias of color.costco-blue
export const ButtonsColorOutlinePrimaryDefaultBackground = "#ffffff"; // alias of color.white
export const ButtonsColorOutlinePrimaryFocusBackground = "#eeeeee"; // alias of color.gray-200
export const ButtonsColorOutlinePrimaryHoverBackground = "#eeeeee"; // alias of color.gray-200
export const ButtonsColorOutlinePrimaryBorder = "#005dab"; // alias of color.costco-blue
export const ButtonsColorOutlinePrimaryText = "#0060a9"; // alias of color.blue-600
export const ButtonsColorOutlineSecondaryDefaultBackground = "#fafafa"; // alias of gray-100
export const ButtonsColorOutlineSecondaryFocusBackground = "#d9d9d9"; // alias of color gray-400
export const ButtonsColorOutlineSecondaryHoverBackground = "#d9d9d9"; // alias of color gray-400
export const ButtonsColorOutlineSecondaryBorder = "#909090"; // alias of color.gray-600
export const ButtonsColorOutlineSecondaryText = "#0060a9"; // alias of color.blue-600
export const ButtonsSizeHeight = "40px";
export const ButtonsSizeBorderWidth = "0";
export const ButtonsSizeSecondaryBorderWidth = "1px";
export const ButtonsSizeOutlinePrimaryBorderWidth = "1px";
export const ButtonsSizeOutlineSecondaryBorderWidth = "1px";
export const ButtonsRadius = "3px"; // alias of radius.md
export const ButtonsFontSize = "1rem"; // alias of font.size.300
export const CardColorBackground = "#ffffff";
export const CardColorBorder = "#909090";
export const CardSpaceInset = "16px 16px 16px 16px";
export const CardSizeBorderWidth = "1px";
export const CardRadius = "2px";
export const CardShadow = "0px 0px 5px 0px rgba(0, 0, 0, 0.30)";
export const DividersColorBorder = "#cccccc"; // alias of color.gray-500
export const DividersSizeBorderWidth = "1px"; // alias of size.border-width.thin
export const DrawerShadow = "5px 5px 16px rgba(0, 0, 0, 0.5)"; // Same as modal shadow
export const FeedbackTextSpaceMargin = "0 0 0 4px";
export const HelpLinkColorText = "#005dab";
export const HelpLinkSpaceMargin = "0 0 0 4px";
export const HelpTextColorDefaultText = "#707070";
export const HelpTextColorErrorText = "#d32029";
export const HelpTextFontErrorWeight = "700";
export const HelpTextSpaceMargin = "0 0 0 4px";
export const InputActiveText = "#333333";
export const InputColorErrorBorder = "#d32029";
export const InputColorErrorText = "#d32029";
export const InputColorFocusBorder = "#005dab";
export const InputColorFocusText = "#d32029";
export const InputCompleteText = "#333333";
export const InputRadius = "3px";
export const InputSizeActiveBorderWidth = "2px";
export const InputSizeDefaultBorderWidth = "1px";
export const InputSizeFocusBorderWidth = "2px";
export const LabelColorCompleteText = "#333333";
export const LabelColorErrorText = "#d32029";
export const LabelColorFocusText = "#005dab";
export const LabelColorInactiveText = "#333333";
export const SelectorCheckboxCheckedShadow = "0px 0px 4px 0px rgba(48, 113, 169, 1)";
export const SelectorCheckboxErrorShadow = "0px 0px 4px 0px rgba(211, 32, 41, 1)";
export const SelectorRadioButtonCheckedShadow = "0px 0px 4px 0px rgba(48, 113, 169, 1)";
export const SelectorRadioButtonErrorShadow = "0px 0px 4px 0px rgba(211, 32, 41, 1)";
export const ListSpaceMargin = "0 8px 0 0";
export const MarketingTypeFontTitleXlLineHeight = "1.3"; // alias of font.line-height.sm
export const MarketingTypeFontTitleXlSize = "3.5rem";
export const MarketingTypeFontTitleXlWeight = "700"; // alias of font.weight.bold
export const MarketingTypeFontTitleMdLineHeight = "1.3"; // alias of font.line-height.sm
export const MarketingTypeFontTitleMdSize = "2.625rem";
export const MarketingTypeFontTitleMdWeight = "700"; // alias of font.weight.bold
export const MarketingTypeFontTitleXsLineHeight = "1.3"; // alias of font.line-height.sm
export const MarketingTypeFontTitleXsSize = "1.625rem";
export const MarketingTypeFontTitleXsWeight = "700"; // alias of font.weight.bold
export const MarketingTypeFontHeadingMdLineHeight = "1.3"; // alias of font.line-height.sm
export const MarketingTypeFontHeadingMdSize = "1.625rem";
export const MarketingTypeFontHeadingMdWeight = "700"; // alias of font.weight.bold
export const MarketingTypeFontHeadingXsLineHeight = "1.3"; // alias of font.line-height.sm
export const MarketingTypeFontHeadingXsSize = "1.25rem";
export const MarketingTypeFontHeadingXsWeight = "700"; // alias of font.weight.bold
export const MarketingTypeFontSubheadingMdLineHeight = "1.3"; // alias of font.line-height.sm
export const MarketingTypeFontSubheadingMdSize = "1.5rem";
export const MarketingTypeFontSubheadingMdWeight = "700"; // alias of font.weight.bold
export const MarketingTypeFontSubheadingXsLineHeight = "1.3"; // alias of font.line-height.sm
export const MarketingTypeFontSubheadingXsSize = "1.125rem";
export const MarketingTypeFontSubheadingXsWeight = "700"; // alias of font.weight.bold
export const MarketingTypeFontBodyCopyLineHeight = "1.3"; // alias of font.line-height.sm
export const MarketingTypeFontBodyCopySize = "1rem";
export const MarketingTypeFontBodyCopyWeight = "400"; // alias of font.weight.default
export const MarketingTypeFontCaptionLineHeight = "1.3"; // alias of font.line-height.sm
export const MarketingTypeFontCaptionSize = "0.75rem";
export const MarketingTypeFontCaptionWeight = "400"; // alias of font.weight.default
export const ModalShadow = "5px 5px 16px rgba(0, 0, 0, 0.5)";
export const NotificationsColorAlertBackground = "#fff8d2"; // alias of color.yellow-100
export const NotificationsColorAlertBorder = "#ffcc00"; // alias of color.yellow-200
export const NotificationsColorAlertText = "#333333"; // alias of color.gray-900
export const NotificationsColorErrorBackground = "#fdd6d6"; // alias of color.red-100
export const NotificationsColorErrorBorder = "#d32029"; // alias of color.red-500
export const NotificationsColorErrorText = "#333333"; // alias of color.gray-900
export const NotificationsColorGeneralBackground = "#eff7fb"; // alias of color.blue-100
export const NotificationsColorGeneralBorder = "#005dab"; // alias of color.blue-500
export const NotificationsColorGeneralText = "#333333"; // alias of color.gray-900
export const NotificationsColorSuccessBackground = "#dff0d8"; // alias of color.green-100
export const NotificationsColorSuccessBorder = "#008000"; // alias of color.green-500
export const NotificationsColorSuccessText = "#333333"; // alias of color.gray-900
export const NotificationsSizeBorderWidth = "2px"; // alias of size.border-width.medium
export const NotificationsRadius = "3px"; // alias of radius.md
export const PillBadgeColorBlueText = "#ffffff"; // alias of color.text.white
export const PillBadgeColorBlueDefaultBackground = "#005dab"; // alias of color.costco-blue
export const PillBadgeColorBlueHoverBackground = "#2a6293"; // alias of color.blue-700
export const PillBadgeColorBlueBorder = "initial";
export const PillBadgeColorDarkGrayText = "#ffffff"; // alias of color.text.white
export const PillBadgeColorDarkGrayDefaultBackground = "#333333"; // alias of color.gray-900
export const PillBadgeColorDarkGrayHoverBackground = "#000000"; // alias of color.black
export const PillBadgeColorDarkGrayBorder = "initial";
export const PillBadgeColorGreenText = "#ffffff"; // alias of color.text.white
export const PillBadgeColorGreenDefaultBackground = "#008000"; // alias of color.green-500
export const PillBadgeColorGreenHoverBackground = "#0b6e22"; // alias of color.green-700
export const PillBadgeColorGreenBorder = "initial";
export const PillBadgeColorOrangeText = "#ffffff"; // alias of color.text.white
export const PillBadgeColorOrangeDefaultBackground = "#D83F03";
export const PillBadgeColorOrangeHoverBackground = "#A63002";
export const PillBadgeColorOrangeBorder = "initial";
export const PillBadgeColorPurpleText = "#ffffff"; // alias of color.text.white
export const PillBadgeColorPurpleDefaultBackground = "#800080";
export const PillBadgeColorPurpleHoverBackground = "#660066";
export const PillBadgeColorPurpleBorder = "initial";
export const PillBadgeColorRedText = "#ffffff"; // alias of color.text.white
export const PillBadgeColorRedDefaultBackground = "#e51937"; // alias of color.costco-red
export const PillBadgeColorRedHoverBackground = "#B8142C";
export const PillBadgeColorRedBorder = "initial";
export const PillBadgeFontSize = "0.75rem"; // alias of font.size.100
export const PillBadgeSizeBorderWidth = "0px";
export const PopoverShadow = "0px 5px 10px rgba(0, 0, 0, 0.2)";
export const SkeletonColorBackground = "#C7C7C7"; // darkest color in the skeleton animation
export const TooltipShadow = "0px 5px 10px rgba(0, 0, 0, 0.2)"; // alias of popover
